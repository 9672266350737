<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="p-4 flex">
        <h1 class="text-3xl">
          Termos
        </h1>
      </div>
      <div class="p-4">
        <div class="flex mb-4 justify-end">
          <router-link
            class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{ name: 'create-term' }"
          >
            Novo Termo
          </router-link>
        </div>
        <v-client-table
          ref="table"
          :data="terms"
          :columns="columns"
          :options="options"
        >
          <div class="flex" slot="actions" slot-scope="{ row }">
            <router-link
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{ name: 'edit-term', params: { id: row.id } }"
              v-tooltip="{ content: 'Editar', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>

            <router-link
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{
                name: 'list-accept',
                params: { id: row.id, title: row.title }
              }"
              v-tooltip="{ content: 'Pesquisar Aceites', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>

            <button
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg"
              @click="deleteTerm(row, index)"
              v-tooltip="{ content: 'Deletar', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm-8 5v6h2v-6H9zm4 0v6h2v-6h-2zM9 4v2h6V4H9z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </button>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'IndexTerm',

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: false,
      terms: [],
      columns: ['id', 'title', 'acceptances', 'actions'],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          title: 'Título',
          acceptances: 'Aceites',
          actions: 'Ações'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} registros|{count} registro|Um registro',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum registro encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },
  methods: {
    async fetchTerms() {
      this.isLoading = true;
      await axios.get('/api/termos').then(({ data }) => {
        data.data.forEach(term => this.terms.push(term));
      });
      this.isLoading = false;
    },

    deleteTerm(row, index) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir este termo?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            this.isLoading = true;
            axios.delete(`/api/termos/${row.id}`).then(() => {
              this.terms.splice(--index, 1);
              this.isLoading = false;
            });
          }
        }
      });
    }
  },
  created() {
    this.fetchTerms();
  }
};
</script>

<style scoped></style>
